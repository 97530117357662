import React from "react"
import Head from "../../components/Head"
import Header from "../../components/Headers/Header"
import IndexHero from "../../components/Index/IndexHero"
import GoForMore from "../../components/Index/GoForMore"
import IndexChapters from "../../components/Index/IndexChapters"
import Footer from "../../components/Footers/Footer"
import PosterModal from "../../components/Modals/PosterModal"
//import Layout from "../components/layout"
//import Image from "../components/image"
//import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <Head pageTitle="Between.Pomiędzy" />
    <Header title="Start" />
    <main className="main">
      <IndexHero />
      <GoForMore />
      <IndexChapters />
    </main>
    <Footer />
    <PosterModal />
  </>
)

export default IndexPage
