import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { langVersion } from "../../accessors"
import "./PosterModal.scss"
import { MODAL_IMAGE_SRC } from "../../constans"

const PosterModal = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalOpen, setModalOpen] = useState(true)
  useEffect(() => {
    const currentDay = Date.now()
    const startDay = new Date("April 27, 2024")
    const endDay = new Date("July 1, 2024")
    const startTime = startDay.getTime()
    const endTime = endDay.getTime()
    if (currentDay > startTime && currentDay < endTime) {
      setModalVisible(true)
    } else {
      setModalVisible(false)
    }
  })

  return (
    <>
      {modalVisible && (
        <dialog className="page-modal" open={modalOpen}>
          <button
            className="modal-btn--close"
            onClick={() => setModalOpen(!modalOpen)}
          ></button>
          <div className="modal-box">
            <img className="modal-image" src={MODAL_IMAGE_SRC} />
            {/* <Link
              className="modal-btn--go"
              to={langVersion(
                // "/festivals/13-festival/dispersed-festival-2023",
                // "/en/festivals/13-festival/dispersed-festival-2023"
                "/news",
                "/en/news"
              )}
            >
              {langVersion("Aktualności", "News")}
            </Link> */}
            <div className="modal-btn-box">
              <Link
                className="modal-btn--go"
                to={langVersion(
                  // "/festivals/13-festival/dispersed-festival-2023",
                  // "/en/festivals/13-festival/dispersed-festival-2023"
                  "/news",
                  "/en/news"
                )}
              >
                {langVersion("Więcej", "More")}
              </Link>
            </div>
            {/* <div className="modal-btn-box">
              <a
                className="modal-btn--go"
                target="_blank"
                title={langVersion(
                  "Przejdź do strony z trailerem",
                  "Go to the trailer page"
                )}
                href="https://vimeo.com/801355786?fbclid=IwAR342risOS3pTsrujzXfXC_nHD_1ABWZHB9IQsnPUd6EmU71tIrzCWhoZw0"
              >
                {langVersion("Trailer", "Trailer")}
              </a>
              <a
                className="modal-btn--go"
                target="_blank"
                title={langVersion(
                  "Przejdź do strony z informacją",
                  "Go to the information page"
                )}
                href="https://ug.edu.pl/news/pl/5674/polska-premiera-filmu-tanca-home-w-ramach-festiwalu-rozproszonego-betweenpomiedzy-2023"
              >
                {langVersion("Info", "Info")}
              </a>
            </div> */}
          </div>
        </dialog>
      )}
    </>
  )
}

export default PosterModal
